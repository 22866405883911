import React from 'react'

export default function Testimonials() {
  return (
    <section
      className="u-clearfix u-palette-5-light-2 u-section-7"
      id="carousel_80b4"
    >
      <div className="u-clearfix u-sheet u-sheet-1">
        <div
          id="carousel-d79e"
          data-interval={5000}
          data-u-ride="carousel"
          className="u-carousel u-slider u-slider-1"
        >
          <ol className="u-absolute-hcenter u-carousel-indicators u-carousel-indicators-1">
            <li
              data-u-target="#carousel-d79e"
              className="u-active u-grey-30 u-shape-circle"
              data-u-slide-to={0}
              style={{ width: 10, height: 10 }}
            />
            <li
              data-u-target="#carousel-d79e"
              className="u-grey-30 u-shape-circle"
              data-u-slide-to={1}
              style={{ width: 10, height: 10 }}
            />
          </ol>
          <div className="u-carousel-inner" role="listbox">
            <div className="u-active u-align-center u-carousel-item u-container-style u-slide">
              <div className="u-container-layout u-valign-top u-container-layout-1">
                <a href="https://www.mid-day.com/brand-media/article/tarun-singh-rawat-has-caught-filmmakers-attention-in-making-devbhoomi-the-next-film-capital-23229188">
                   <div className=' flex justify-center my-40 md:my-0'>
                  <img src="image/S1.jpg" className=' w-[100%] md:w-[70%]' alt="" />
                </div>
                </a>
               
              </div>
            </div>
            <div className="u-align-center u-carousel-item u-container-style u-slide">
              <div className="u-container-layout u-valign-top u-container-layout-2">

<a href="https://www.mid-day.com/brand-media/article/tarun-singh-rawat-has-caught-filmmakers-attention-in-making-devbhoomi-the-next-film-capital-23229188">
  <div className=' flex justify-center my-40 md:my-0'>
                  <img src="image/S2.jpg" className=' w-[100%] md:w-[70%]' alt="" />
                </div>
</a>

                
              </div>
            </div>
          </div>
          <a
            className="u-absolute-vcenter u-carousel-control u-carousel-control-prev u-icon-circle u-text-body-color u-text-hover-grey-50 u-carousel-control-1"
            href="#carousel-d79e"
            role="button"
            data-u-slide="prev"
          >
            <span aria-hidden="true">
              <svg viewBox="0 0 477.175 477.175">
                <path
                  d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
              c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                />
              </svg>
            </span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="u-absolute-vcenter-lg u-absolute-vcenter-md u-absolute-vcenter-sm u-absolute-vcenter-xs u-carousel-control u-carousel-control-next u-icon-circle u-text-body-color u-text-hover-grey-50 u-carousel-control-2"
            href="#carousel-d79e"
            role="button"
            data-u-slide="next"
          >
            <span aria-hidden="true">
              <svg viewBox="0 0 477.175 477.175">
                <path
                  d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
              c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"
                />
              </svg>
            </span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </section>
  )
}

