import React from 'react'

export default function About() {
  return (
    <section className="u-clearfix u-section-4" id="about">
      <div className="u-clearfix u-sheet u-valign-middle-lg u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-sheet-1">
        <h1 className="u-text u-text-1">About me</h1>
        <div className="u-border-8 u-border-palette-2-base u-line u-line-horizontal u-line-1" />
        <h6 className="u-text u-text-2 text-[#F0A056] text-3xl">
          A Persevering Entrepreneur & Ideologist
        </h6>
        <p className=" text-justify">
          Tarun Singh Rawat - the force behind JSR Group, has come a long way from his humble beginnings as an entrepreneur to becoming the vice-chairperson of JSR Group. He is a man of action and takes bold decisions. He believes success does not come easy; to achieve it, one must believe in himself and work continuously to achieve it. His dedication has led him to conquer great heights at a nascent age.
          As a young, successful producer and businessman, Tarun Singh Rawat has been working hard to build a legacy as one of the most influential names in his industry. He is known for his ability to spot trends early and turn them into profit and won several awards for the same. It's no wonder that he has made such an impact on the world around him. As a leader, he is known for his ability to motivate others and drive them towards success, but what makes him truly remarkable are his values and integrity.
        </p>
        <p className=" text-justify">
          Tarun Singh Rawat was never motivated by fame. Instead, by his passion to uplift society through hard work and constant effort. At a very young age, Tarun began planning his future. He knew what he wanted to do and how he would get there. As a result, today we see him as a successful businessman.
          Little did he know that his efforts would reap him good results. He once again surprised the world by entering the film industry with his first-ever movie- 72 hours, a biopic of Rifleman Jaswant Singh Rawat. The movie was screened across 800 theatres and received lots of appreciation from the local audience also. This movie was a turning point in his career and he decided to take the plunge into the Bollywood industry by establishing his own production house in Dehradun.
        </p>
        <h6 className=' text-[#F0A056] text-3xl'>Passion For His State</h6>
        <p className=" text-justify">
          His love for his state, Uttarakhand, is endless; therefore, he was determined to promote local talent through his movies. Social work is one of his core characteristics and he has taken it one step further by taking care of the poor inhabitants of Dehradun are well taken care of, and to ensure this he has collaborated with many NGOs. His active involvement in social work helps him stay grounded and close to people. He believes in using his wealth for the betterment of society.
        </p>
        <img
          src="img/about.jpg"
          alt=""
          className="u-image u-image-default u-image-1"
          data-image-width={1200}
          data-image-height={800}
        />

        <div className="u-clearfix u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-container-style u-layout-cell u-size-34-md u-size-34-sm u-size-34-xs u-size-38-lg u-size-38-xl u-layout-cell-1">
                <div className="u-container-layout u-container-layout-1">
                  <h6 className='text-[#F0A056] text-xl md:text-3xl'>Recognized Nationwide for his Excellence</h6>
                  <p className=" font-normal text-justify">
                    His business ventures are recognised by several prestigious national newspapers and magazines
                    such as Times of India, Midday, Business Today, Financial Express etc. He has been interviewed on
                    various topics such as entrepreneurship, innovation and technology. After becoming a successful hotelier and businessman, he was determined to expand his footings into trading and consultancy firms too. He achieved it by establishing a trading and consultancy firm under JSR's banner and added it as a part of his portfolio. His efforts were rewarded when he was able to scale them into full-fledged business ventures that became a landmark due to their unique approach and marketing strategies. Tarun Singh Rawat looks up to his father Jaswant Singh Rawat, who has been a constant source of motivation for him. His values and work ethics are all imbibed gracefully by him. His never giving up attitude is what sets him apart from the others and this helps him excel in all his endeavours. He is budding fame in Uttarakhand and continues to strengthen his legacy with his efforts to uplift society. He has a strong message for the Youth- "The Youth is the future of this world and if you want to be part of it, then you must be prepared for all challenges that life has for you."
                  </p>
                </div>
              </div>
              <div className="u-container-style u-layout-cell u-palette-2-base u-size-22-lg u-size-22-xl u-size-26-md u-size-26-sm u-size-26-xs u-layout-cell-2">
                <div className="u-container-layout u-container-layout-2">
                  <h6 className="u-text u-text-6">email</h6>
                  <a
                    href="mailto:tarunrawat5555@gmail.com"
                    className="u-active-none u-btn u-button-style u-hover-none u-none u-text-body-alt-color u-btn-2"
                  >
                    tarunrawat5555@gmail.com
                  </a>
                  <h6 className="u-text u-text-7">Role</h6>
                  <p className="u-text u-text-8">Vice Chairman</p>
                  <h6 className="u-text u-text-9">Phone</h6>
                  <a
                    href="tel:+91 97608 83353"
                    className="u-active-none u-btn u-button-style u-hover-none u-none u-text-body-alt-color u-btn-3"
                  >
                    +91 97608 83353
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
