import React from 'react'
import { BsFacebook, BsTwitter, BsInstagram } from 'react-icons/bs';

export default function Doing() {
  return (
    <>
      <div>
        <div className='bg-[#E9EEF4] p-10' id='product'>
          <section className=" container mx-auto ">
            <div>
              <h2 className=' text-center uppercase'>Establishments</h2>
              <span className=' flex justify-center text-sm md:text-lg text'>We are a conglomerate that has been in business for more than 20 years, serving our customers with the best products and services.</span>
            </div>
            <div className=" md:flex py-10 items-center ">
              <div className="left">
                <div className="">
                  <div className="logo flex items-center">
                    <img className=' rounded-full w-11' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSklHJzJMEEjmB-dxdP9o8r8dLVFuBX0crzNg&usqp=CAU" alt="" />
                    <div className=' mx-2 text-lg md:text-2xl font-bold'>J.S.R. Builders And Developers</div>
                  </div>
                  <p className=' md:mx-[3.5em] text-justify md:w-[34em]'>J.S.R. Builders And Developers India Pvt. Ltd. have been continuously delivering new dimensions in the architecture sector of the prominent cities in Uttarakhand for the past nine years.</p>
                  <div className=' flex md:mx-[3.5em]  justify-between -mt-2 md:-mt-0'>
                    <div className="left1 ">
                      <div className=' '>Estd. year : 2011</div>
                    </div>
                    <div className="righ1">
                      <div className=" flex">
                        <BsFacebook className=' mx-2' />
                        <BsTwitter className=' mx-2' />
                        <BsInstagram className=' mx-2' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="right my-3 md:my-0">
                <img src="image/jsr.jpg" alt="" />
              </div>
            </div>
            <div className=" md:flex py-10 items-center -mt-14 md:-mt-0 ">
              <div className="left">
                <div className="">
                  <div className="logo flex items-center">
                    <img className=' rounded-full w-11' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSklHJzJMEEjmB-dxdP9o8r8dLVFuBX0crzNg&usqp=CAU" alt="" />
                    <div className=' mx-2 text-lg md:text-2xl font-bold'>J.S.R. Group of Hotels</div>
                  </div>
                  <p className=' md:mx-[3.5em] text-justify md:w-[34em]'>J.S.R. Group of Hotels was started in 2013 for serving the nation with the belief of "Atithi Devo bhava, we strive to achieve a milestone in the service industry and become an inspiration in the hospitality sector."</p>
                  <div className=' flex md:mx-[3.5em]  justify-between -mt-2 md:-mt-0'>
                    <div className="left1 ">
                      <div className=' '>Estd. year : 2011</div>
                    </div>
                    <div className="righ1">
                      <div className=" flex">
                        <BsFacebook className=' mx-2' />
                        <BsTwitter className=' mx-2' />
                        <BsInstagram className=' mx-2' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="right my-3 md:my-0">
                <img src="image/jsr.jpg" alt="" />
              </div>
            </div>


            <div className=" md:flex py-10 items-center -mt-14 md:-mt-0">
              <div className="left">
                <div className="">
                  <div className="logo flex items-center">
                    <img className=' rounded-full w-11' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSklHJzJMEEjmB-dxdP9o8r8dLVFuBX0crzNg&usqp=CAU" alt="" />
                    <div className=' mx-2 text-lg md:text-2xl font-bold'>J.S.R. Production House</div>
                  </div>
                  <p className=' md:mx-[3.5em] text-justify md:w-[34em]'>J.S.R. Production House is a group of headstrong, talented, and ideologist youngsters who can transform imaginations into reality. We are a production house that believes</p>
                  <div className=' flex md:mx-[3.5em]  justify-between -mt-2 md:-mt-0'>
                    <div className="left1 ">
                      <div className=' '>Estd. year : 2017</div>
                    </div>
                    <div className="righ1">
                      <div className=" flex">
                        <BsFacebook className=' mx-2' />
                        <BsTwitter className=' mx-2' />
                        <BsInstagram className=' mx-2' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="right my-3 md:my-0">
                <img src="image/production.jpg" alt="" />
              </div>
            </div>

            <div className=" md:flex py-10 items-center -mt-14 md:-mt-0">
              <div className="left">
                <div className="">
                  <div className="logo flex items-center">
                    <img className=' rounded-full w-11' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSklHJzJMEEjmB-dxdP9o8r8dLVFuBX0crzNg&usqp=CAU" alt="" />
                    <div className=' mx-2 text-lg md:text-2xl font-bold'>J.S.R. Trading company</div>
                  </div>
                  <p className=' md:mx-[3.5em] text-justify md:w-[34em]'>J.S.R. Trading company was introduced in 2018 to trade goods, including FMCGS, Hospital and wellness Equipment, Industrial Materials, Consumer Products, and more.</p>
                  <div className=' flex md:mx-[3.5em]  justify-between -mt-2 md:-mt-0'>
                    <div className="left1 ">
                      <div className=' '>Estd. year : 2018</div>
                    </div>
                    <div className="righ1">
                      <div className=" flex">
                        <BsFacebook className=' mx-2' />
                        <BsTwitter className=' mx-2' />
                        <BsInstagram className=' mx-2' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="right my-3 md:my-0">
                <img src="image/jsr.jpg" alt="" />
              </div>
            </div>

            <div className=" md:flex py-10 items-center -mt-14 md:-mt-0">
              <div className="left">
                <div className="">
                  <div className="logo flex items-center">
                    <img className=' rounded-full w-11' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSklHJzJMEEjmB-dxdP9o8r8dLVFuBX0crzNg&usqp=CAU" alt="" />
                    <div className=' mx-2 text-lg md:text-2xl font-bold'>J.S.R. Counsellor</div>
                  </div>
                  <p className=' md:mx-[3.5em] text-justify md:w-[34em]'>J.S.R. Counsellor Pvt. Ltd is a business and management consultancy company that commenced on 28 June 2019 in Dehradun. It offers a wide range of services to businesses,</p>
                  <div className=' flex md:mx-[3.5em]  justify-between -mt-2 md:-mt-0'>
                    <div className="left1 ">
                      <div className=' '>Estd. year : 2011</div>
                    </div>
                    <div className="righ1">
                      <div className=" flex">
                        <BsFacebook className=' mx-2' />
                        <BsTwitter className=' mx-2' />
                        <BsInstagram className=' mx-2' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="right my-3 md:my-0">
                <img src="image/jsr.jpg" alt="" />
              </div>
            </div>

            <div className=" md:flex py-10 items-center -mt-14 md:-mt-0">
              <div className="left">
                <div className="">
                  <div className="logo flex items-center">
                    <img className=' rounded-full w-11' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSklHJzJMEEjmB-dxdP9o8r8dLVFuBX0crzNg&usqp=CAU" alt="" />
                    <div className=' mx-2 text-lg md:text-2xl font-bold'>J.S.R. Record label</div>
                  </div>
                  <p className=' md:mx-[3.5em] text-justify md:w-[34em]'>J.S.R. Builders And Developers India Pvt. Ltd. have been continuously delivering new dimensions in the architecture sector of the prominent cities in Uttarakhand for the past nine years.</p>
                  <div className=' flex md:mx-[3.5em]  justify-between -mt-2 md:-mt-0'>
                    <div className="left1 ">
                      <div className=' '>Estd. year : 2011</div>
                    </div>
                    <div className="righ1">
                      <div className=" flex">
                        <BsFacebook className=' mx-2' />
                        <BsTwitter className=' mx-2' />
                        <BsInstagram className=' mx-2' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="right my-3 md:my-0">
                <img src="image/recordlabel.jpg" alt="" />
              </div>
            </div>

            <div className=" md:flex py-10 items-center -mt-14 md:-mt-0">
              <div className="left">
                <div className="">
                  <div className="logo flex items-center">
                    <img className=' rounded-full w-11' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSklHJzJMEEjmB-dxdP9o8r8dLVFuBX0crzNg&usqp=CAU" alt="" />
                    <div className=' mx-2 text-lg md:text-2xl font-bold'>VHS Media LLP</div>
                  </div>
                  <p className=' md:mx-[3.5em] text-justify md:w-[34em]'>J.S.R. Builders And Developers India Pvt. Ltd. have been continuously delivering new dimensions in the architecture sector of the prominent cities in Uttarakhand for the past nine years.</p>
                  <div className=' flex md:mx-[3.5em]  justify-between -mt-2 md:-mt-0'>
                    <div className="left1 ">
                      <div className=' '>Estd. year : 2011</div>
                    </div>
                    <div className="righ1">
                      <div className=" flex">
                        <BsFacebook className=' mx-2' />
                        <BsTwitter className=' mx-2' />
                        <BsInstagram className=' mx-2' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="right my-3 md:my-0">
                <img src="image/vhs.jpg" alt="" />
              </div>
            </div>


          </section>
        </div>
      </div>
    </>

  )
}
