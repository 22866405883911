import React from 'react'
import About from '../components/About'
import Doing from '../components/Doing'
import Footer from '../components/Footer'
import Gallery from '../components/Gallery'
import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import Profile from '../components/Profile'
import Testimonials from '../components/Testimonials'

export default function Home() {
  return (
    <>
    <Navbar/>
    <Hero/>
    {/* <Profile/> */}
    <About/>
    <Doing/>
    <Gallery/>
    <Testimonials/>
    <Footer/>
    </>
  )
}
