import React, { useState } from 'react'
import '../styles/Navbar.css'
import { BiMenu } from 'react-icons/bi'
import { RxCross2 } from 'react-icons/rx'

export default function Navbar() {
    const [isOpen, setIsopen] = useState(false);

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    }
    return (
        <>
            <div className=" ">

                <nav className="navbar navbar-expand-lg navbar-light sticky" >
                    <div className="container p-2">
                        <a href='/' className="navbar-brand text-primary mr-0" style={{ fontSize: '23px' }}>TSR</a>
                        <div className="form-inline ml-auto">

                            <a href="TSR Business Card.pdf" download> <button type="button" className=" mx-3 focus:outline-none text-black bg-[#F0A056] hover:bg-yellow-500  rounded-lg text-sm px-5 py-2.5 mr-2  font-bold">Download</button></a>
                            <div className="btn btn-primary" style={{ cursor: 'pointer' }} onClick={ToggleSidebar} >
                                <BiMenu size={25} />
                            </div>


                        </div>
                    </div>
                </nav>
                <div className={`sidebar ${isOpen === true ? 'active' : ''}`}>
                    <div className="sd-header">
                        <h4 className="mb-0">PortFolio</h4>
                        <div className="btn btn-primary" onClick={ToggleSidebar}>
                            <RxCross2 size={25} />
                        </div>
                    </div>
                    <div className="sd-body">
                        <ul>
                            <li><a href='#' className="sd-link">Home</a></li>
                            <li><a href='#about' className="sd-link">About</a></li>
                            <li><a href='#product' className="sd-link">Business</a></li>
                            <li><a href='#portfolio' className="sd-link">Portfolio</a></li>
                             <li><a href='#contact' className="sd-link">Contact Us</a></li>
                            {/* <li><a href='/' className="sd-link">Home</a></li>
                            <li><a href='/' className="sd-link">About</a></li>
                            <li><a href='/' className="sd-link">Contact Us</a></li>
                            <li><a href='/' className="sd-link">Services</a></li>
                            <li><a href='/' className="sd-link">Product</a></li> */}

                        </ul>
                    </div>
                </div>
                <div className={`sidebar-overlay ${isOpen === true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
            </div>

        </>
    )
}
