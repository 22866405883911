import React from 'react';
import { BsFacebook, BsTwitter, BsInstagram } from 'react-icons/bs';

export default function Hero() {
  return (
    <section
      className="u-clearfix u-image u-section-2"
      id="carousel_9805"
      data-image-width={1980}
      data-image-height={1320}
    >
      <div className="u-clearfix u-layout-wrap u-layout-wrap-1">
        <div className="u-layout">
          <div className="u-layout-row">
            <div className="u-align-left u-container-style u-layout-cell u-left-cell u-size-26-lg u-size-26-xl u-size-29-md u-size-29-sm u-size-29-xs u-layout-cell-1">
              <div className="u-container-layout u-valign-middle u-container-layout-1">
                {/* <div className="u-palette-2-base u-shape u-shape-circle u-shape-1" /> */}
                <h1 className="u-custom-font u-font-raleway u-text u-title u-text-1">
                  Hello, <br /> I am Tarun Singh Rawat
                </h1>
                <p className="u-text u-text-2__edit font-bold">
                  {" "}
                  Business Entrepreneur and Hotelier
                </p>
                <p className="u-text u-text-2">
                  {" "}
                  "Great things are not build in a day, but they start with a single step. To be great, one must be willing to start over and over again"
                </p>

                <div
                  href="https://nicepage.com/joomla-page-builder"
                  className="u-black u-btn u-button-style u-hover-palette-2-base u-btn-2"
                >
                  <div className=' flex '>
                    <BsFacebook style={{ marginLeft: '12px' }} color={'#4D4DF0'} />
                    <BsTwitter style={{ marginLeft: '12px' }} color={'#0090F7'} />
                    <BsInstagram style={{ marginLeft: '12px' }} color={'red'} />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="u-container-style u-image u-layout-cell u-right-cell u-size-31-md u-size-31-sm u-size-31-xs u-size-34-lg u-size-34-xl u-image-1"
              data-image-width={1200}
              data-image-height={1138}
            >
              <div className="u-container-layout u-container-layout-2" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
