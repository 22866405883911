// import React from 'react'

// export default function Footer() {
//     return (
//         <>
//             <section
//                 className="u-clearfix u-palette-4-dark-3 u-section-8"
//                 id="carousel_b8d1"
//             >
//                 <div className="u-clearfix u-sheet u-sheet-1">
//                     <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
//                         <div className="u-layout">
//                             <div className="u-layout-row">
//                                 {/* <div className="u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-1">
//                                     <div className="u-container-layout u-container-layout-1">
//                                         <h5 className="u-text u-text-1">Ready to talk?</h5>
//                                         <h2 className="u-text u-text-2">Contact Me</h2>
//                                         <p className="u-text u-text-3">
//                                             Massa tempor nec feugiat nisl pretium fusce id velit. Accumsan
//                                             tortor posuere ac ut. Aenean pharetra magna ac placerat
//                                             vestibulum lectus. Praesent semper feugiat nibh sed pulvinar.
//                                             Lorem sed risus ultricies tristique.&nbsp;
//                                             <br />
//                                             <br />
//                                             Dictumst vestibulum rhoncus est pellentesque elit ullamcorper.
//                                             Amet dictum sit amet justo donec enim diam vulputate. Ut morbi
//                                             tincidunt augue interdum velit euismod in pellentesque.
//                                             Volutpat lacus laoreet non curabitur.
//                                         </p>
//                                     </div>
//                                 </div> */}
//                                 <div className="u-container-style u-layout-cell u-right-cell u-size-30 u-layout-cell-2">
//                                     <div className="u-container-layout u-valign-top u-container-layout-2">
//                                         <div className="u-expanded-width u-form u-form-1">
//                                             <form
//                                                 action="https://nicepage.com/editor/Forms/Process"
//                                                 method="POST"
//                                                 className="u-block-1f41-12 u-clearfix u-form-spacing-15 u-form-vertical u-inner-form"
//                                                 source="email"
//                                             >
//                                                 {/* hidden inputs for siteId and pageId */}
//                                                 <input
//                                                     type="hidden"
//                                                     id="siteId"
//                                                     name="siteId"
//                                                     defaultValue={324069}
//                                                 />
//                                                 <input
//                                                     type="hidden"
//                                                     id="pageId"
//                                                     name="pageId"
//                                                     defaultValue={344478}
//                                                 />
//                                                 <div className="u-form-group u-form-name">
//                                                     <label
//                                                         htmlFor="name-f798"
//                                                         className="u-form-control-hidden u-label"
//                                                     >
//                                                         Name
//                                                     </label>
//                                                     <input
//                                                         type="text"
//                                                         placeholder="Name*"
//                                                         id="name-f798"
//                                                         name="name"
//                                                         className="u-border-2 u-border-no-left u-border-no-right u-border-no-top u-border-white u-input u-input-rectangle"
//                                                         required=""
//                                                     />
//                                                 </div>
//                                                 {/* always visible */}
//                                                 <div className="u-form-email u-form-group">
//                                                     <label
//                                                         htmlFor="email-f798"
//                                                         className="u-form-control-hidden u-label"
//                                                     >
//                                                         Email
//                                                     </label>
//                                                     <input
//                                                         type="email"
//                                                         placeholder="Email*"
//                                                         id="email-f798"
//                                                         name="email"
//                                                         className="u-border-2 u-border-no-left u-border-no-right u-border-no-top u-border-white u-input u-input-rectangle"
//                                                         required=""
//                                                     />
//                                                 </div>
//                                                 <div className="u-form-email u-form-group">
//                                                     <label
//                                                         htmlFor="email-f798"
//                                                         className="u-form-control-hidden u-label"
//                                                     >
//                                                         Email
//                                                     </label>
//                                                     <input
//                                                         type="email"
//                                                         placeholder="Subject*"
//                                                         id="email-f798"
//                                                         name="email"
//                                                         className="u-border-2 u-border-no-left u-border-no-right u-border-no-top u-border-white u-input u-input-rectangle"
//                                                         required=""
//                                                     />
//                                                 </div>
//                                                 <div className="u-form-group u-form-message">
//                                                     <label
//                                                         htmlFor="message-f798"
//                                                         className="u-form-control-hidden u-label"
//                                                     >
//                                                         Message
//                                                     </label>
//                                                     <textarea
//                                                         placeholder="Message*"
//                                                         rows={4}
//                                                         cols={50}
//                                                         id="message-f798"
//                                                         name="message"
//                                                         className="u-border-2 u-border-no-left u-border-no-right u-border-no-top u-border-white u-input u-input-rectangle"
//                                                         required=""
//                                                         defaultValue={""}
//                                                     />
//                                                 </div>
//                                                 <div className="u-form-group u-form-submit">
//                                                     <a
//                                                         href="/"
//                                                         className="u-active-white u-border-2 u-border-active-white u-border-hover-white u-border-white u-btn u-btn-submit u-button-style u-hover-white u-none u-text-active-palette-4-base u-text-body-alt-color u-text-hover-palette-4-base u-btn-1"
//                                                     >
//                                                         Submit
//                                                     </a>
//                                                     <input
//                                                         type="submit"
//                                                         defaultValue="submit"
//                                                         className="u-form-control-hidden"
//                                                     />
//                                                 </div>
//                                                 <div className="u-form-send-message u-form-send-success">
//                                                     {" "}
//                                                     Thank you! Your message has been sent.{" "}
//                                                 </div>
//                                                 <div className="u-form-send-error u-form-send-message">
//                                                     {" "}
//                                                     Unable to send your message. Please fix errors then try
//                                                     again.{" "}
//                                                 </div>
//                                                 <input
//                                                     type="hidden"
//                                                     defaultValue=""
//                                                     name="recaptchaResponse"
//                                                 />
//                                             </form>
//                                         </div>
//                                         <div className="u-social-icons u-spacing-10 u-social-icons-1">
//                                             <a className="u-social-url" target="_blank" href="/">
//                                                 <span className="u-icon u-icon-circle u-social-facebook u-social-icon u-icon-1">
//                                                     <svg
//                                                         className="u-svg-link"
//                                                         preserveAspectRatio="xMidYMin slice"
//                                                         viewBox="0 0 112.2 112.2"
//                                                         style={{}}
//                                                     >
//                                                         <use xlinkHref="/svg-5f4f" />
//                                                     </svg>
//                                                     <svg
//                                                         x="0px"
//                                                         y="0px"
//                                                         viewBox="0 0 112.2 112.2"
//                                                         enableBackground="new 0 0 112.2 112.2"
//                                                         xmlSpace="preserve"
//                                                         id="svg-5f4f"
//                                                         className="u-svg-content"
//                                                     >
//                                                         <path d="M75.5,28.8H65.4c-1.5,0-4,0.9-4,4.3v9.4h13.9l-1.5,15.8H61.4v45.1H42.8V58.3h-8.8V42.4h8.8V32.2 c0-7.4,3.4-18.8,18.8-18.8h13.8v15.4H75.5z" />
//                                                     </svg>
//                                                 </span>
//                                             </a>
//                                             <a className="u-social-url" href="/">
//                                                 <span className="u-icon u-icon-circle u-social-icon u-social-twitter u-icon-2">
//                                                     <svg
//                                                         className="u-svg-link"
//                                                         preserveAspectRatio="xMidYMin slice"
//                                                         viewBox="0 0 112.2 112.2"
//                                                         style={{}}
//                                                     >
//                                                         <use xlinkHref="/svg-a709" />
//                                                     </svg>
//                                                     <svg
//                                                         x="0px"
//                                                         y="0px"
//                                                         viewBox="0 0 112.2 112.2"
//                                                         enableBackground="new 0 0 112.2 112.2"
//                                                         xmlSpace="preserve"
//                                                         id="svg-a709"
//                                                         className="u-svg-content"
//                                                     >
//                                                         <path d="M92.2,38.2c0,0.8,0,1.6,0,2.3c0,24.3-18.6,52.4-52.6,52.4c-10.6,0.1-20.2-2.9-28.5-8.2 c1.4,0.2,2.9,0.2,4.4,0.2c8.7,0,16.7-2.9,23-7.9c-8.1-0.2-14.9-5.5-17.3-12.8c1.1,0.2,2.4,0.2,3.4,0.2c1.6,0,3.3-0.2,4.8-0.7 c-8.4-1.6-14.9-9.2-14.9-18c0-0.2,0-0.2,0-0.2c2.5,1.4,5.4,2.2,8.4,2.3c-5-3.3-8.3-8.9-8.3-15.4c0-3.4,1-6.5,2.5-9.2 c9.1,11.1,22.7,18.5,38,19.2c-0.2-1.4-0.4-2.8-0.4-4.3c0.1-10,8.3-18.2,18.5-18.2c5.4,0,10.1,2.2,13.5,5.7c4.3-0.8,8.1-2.3,11.7-4.5 c-1.4,4.3-4.3,7.9-8.1,10.1c3.7-0.4,7.3-1.4,10.6-2.9C98.9,32.3,95.7,35.5,92.2,38.2z" />
//                                                     </svg>
//                                                 </span>
//                                             </a>
//                                             <a className="u-social-url"  href="/">
//                                                 <span className="u-icon u-icon-circle u-social-icon u-social-instagram u-icon-3">
//                                                     <svg
//                                                         className="u-svg-link"
//                                                         preserveAspectRatio="xMidYMin slice"
//                                                         viewBox="0 0 112.2 112.2"
//                                                         style={{}}
//                                                     >
//                                                         <use xlinkHref="/svg-0b43" />
//                                                     </svg>
//                                                     <svg
//                                                         x="0px"
//                                                         y="0px"
//                                                         viewBox="0 0 112.2 112.2"
//                                                         enableBackground="new 0 0 112.2 112.2"
//                                                         xmlSpace="preserve"
//                                                         id="svg-0b43"
//                                                         className="u-svg-content"
//                                                     >
//                                                         <path d="M55.9,32.9c-12.8,0-23.2,10.4-23.2,23.2s10.4,23.2,23.2,23.2s23.2-10.4,23.2-23.2S68.7,32.9,55.9,32.9z M55.9,69.4c-7.4,0-13.3-6-13.3-13.3c-0.1-7.4,6-13.3,13.3-13.3s13.3,6,13.3,13.3C69.3,63.5,63.3,69.4,55.9,69.4z" />
//                                                         <path d="M79.7,26.8c-3,0-5.4,2.5-5.4,5.4s2.5,5.4,5.4,5.4c3,0,5.4-2.5,5.4-5.4S82.7,26.8,79.7,26.8z" />
//                                                         <path d="M78.2,11H33.5C21,11,10.8,21.3,10.8,33.7v44.7c0,12.6,10.2,22.8,22.7,22.8h44.7c12.6,0,22.7-10.2,22.7-22.7 V33.7C100.8,21.1,90.6,11,78.2,11z M91,78.4c0,7.1-5.8,12.8-12.8,12.8H33.5c-7.1,0-12.8-5.8-12.8-12.8V33.7 c0-7.1,5.8-12.8,12.8-12.8h44.7c7.1,0,12.8,5.8,12.8,12.8V78.4z" />
//                                                     </svg>
//                                                 </span>
//                                             </a>
//                                             <a className="u-social-url" target="_blank" href="/">
//                                                 <span className="u-icon u-icon-circle u-social-behance u-social-icon u-icon-4">
//                                                     <svg
//                                                         className="u-svg-link"
//                                                         preserveAspectRatio="xMidYMin slice"
//                                                         viewBox="0 0 112.2 112.2"
//                                                         style={{}}
//                                                     >
//                                                         <use xlinkHref="/svg-b173" />
//                                                     </svg>
//                                                     <svg
//                                                         x="0px"
//                                                         y="0px"
//                                                         viewBox="0 0 112.2 112.2"
//                                                         enableBackground="new 0 0 112.2 112.2"
//                                                         xmlSpace="preserve"
//                                                         id="svg-b173"
//                                                         className="u-svg-content"
//                                                     >
//                                                         <rect x="70.3" y={32} width={23} height="6.9" />
//                                                         <path d="M48.3,54c0,0,8.5-0.6,8.5-10.5s-6.9-14.8-15.7-14.8H12v55.9h29.1c0,0,17.8,0.6,17.8-16.5 C58.7,68.1,59.5,54.2,48.3,54z M24.8,38.5h16.3c0,0,4,0,4,5.8s-2.4,6.6-5,6.6H24.8V38.5z M40.3,74.5H24.8V59.6h16.3c0,0,6,0,6,7.7 C46.9,73.7,42.5,74.5,40.3,74.5z" />
//                                                         <path d="M82.3,43C61,43,61,64.2,61,64.3c0,0-1.5,21.3,21.3,21.3c0,0,19.1,1.2,19.1-14.8h-9.7c0,0,0.2,6-8.9,6 c0,0-9.7,0.7-9.7-9.6h29C101.8,67.2,105,42.8,82.3,43z M72.8,59.6c0,0,1.2-8.6,9.7-8.6s8.5,8.6,8.6,8.6H72.8z" />
//                                                     </svg>
//                                                 </span>
//                                             </a>
//                                             <a className="u-social-url" target="_blank" href="/">
//                                                 <span className="u-icon u-icon-circle u-social-icon u-social-pinterest u-icon-5">
//                                                     <svg
//                                                         className="u-svg-link"
//                                                         preserveAspectRatio="xMidYMin slice"
//                                                         viewBox="0 0 112.2 112.2"
//                                                         style={{}}
//                                                     >
//                                                         <use xlinkHref="/svg-9a6f" />
//                                                     </svg>
//                                                     <svg
//                                                         x="0px"
//                                                         y="0px"
//                                                         viewBox="0 0 112.2 112.2"
//                                                         enableBackground="new 0 0 112.2 112.2"
//                                                         xmlSpace="preserve"
//                                                         id="svg-9a6f"
//                                                         className="u-svg-content"
//                                                     >
//                                                         <path d="M61.9,79.8c-5.5-0.3-7.8-3.1-12-5.8c-2.3,12.4-5.4,24.3-13.8,30.5c-2.6-18.7,3.8-32.8,6.9-47.7 c-5.1-8.7,0.7-26.2,11.5-21.9c13.5,5.4-11.6,32.3,5.1,35.7c17.6,3.5,24.7-30.5,13.8-41.4c-15.7-16.1-45.7-0.5-42,22.3 c0.9,5.6,6.7,7.2,2.3,15c-10.1-2.2-13-10.2-12.7-20.7c0.6-17.3,15.5-29.3,30.5-31.1c19-2.2,36.8,6.9,39.2,24.7 C93.4,59.5,82.3,81.3,61.9,79.8z" />
//                                                     </svg>
//                                                 </span>
//                                             </a>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <footer
//                 className="u-align-center u-clearfix u-footer u-grey-80 u-footer"
//                 id="sec-d13a"
//             >
//                 {/* <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
//                     <p className="u-small-text u-text u-text-variant u-text-1">
//                         Sample text. Click to select the text box. Click again or double click
//                         to start editing the text.
//                     </p>
//                 </div> */}
//             </footer>
//             {/* <section className="u-backlink u-clearfix u-grey-80">
//                 <a
//                     className="u-link"
//                     href="https://nicepage.com/website-templates"
//                     target="_blank"
//                 >
//                     <span>Website Templates</span>
//                 </a>
//                 <p className="u-text">
//                     <span>created with</span>
//                 </p>
//                 <a className="u-link" href="https://nicepage.com/" target="_blank">
//                     <span>Website Builder Software</span>
//                 </a>
//                 .
//             </section> */}
//         </>
//     )
// }


import React from 'react'

function Footer() {
    return (
        <div>
            <section className=" text-gray-100 body-font relative bg-gray-900" id='contact'>
                <div>
                    <div className="container px-5 p-3 md:py-24 mx-auto">
                        <div className="flex flex-col text-center w-full mb-12">
                            <h1 className="sm:text-3xl text-2xl font-medium  title-font text-gray-100">Send me a message</h1>
                        </div>
                        <div className="lg:w-1/2 md:w-2/3 mx-auto">
                            <div className="flex flex-wrap -m-2">
                                <div className="p-2 md:w-1/2 w-full">
                                    <div className="relative">
                                        <label htmlFor="name" className="leading-7 text-sm  text-gray-100">Name*</label>
                                        <input type="text" id="name" name="name" className="w-full bg-gray-300  bg-opacity-50    text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div className="p-2 md:w-1/2 w-full">
                                    <div className="relative">
                                        <label htmlFor="email" className="leading-7 text-sm  text-gray-100">Email*</label>
                                        <input type="email" id="email" name="email" className="w-full bg-gray-300  bg-opacity-50    text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div className="p-2  w-full">
                                    <div className="relative">
                                        <label htmlFor="email" className="leading-7 text-sm  text-gray-100">Subject*</label>
                                        <input type="email" id="email" name="email" className="w-full  bg-gray-300 bg-opacity-50    text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                                    </div>
                                </div>
                                <div className="p-2 w-full">
                                    <div className="relative">
                                        <label htmlFor="message" className="leading-7 text-sm  text-gray-100">Message*</label>
                                        <textarea id="message" name="message" className="w-full bg-gray-300  bg-opacity-50      h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" defaultValue={""} />
                                    </div>
                                </div>
                                <div className="p-3 w-full flex justify-center">
                                    <button className="  text-black bg-[#F0A056] border-0 py-2 px-5 focus:outline-none rounded-[93px] text-sm">Send Message</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default Footer