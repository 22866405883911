import React from 'react'

export default function Gallery() {
  return (
    <section className="u-clearfix u-white u-section-6" id="portfolio">
      <div className="u-clearfix u-sheet u-valign-middle-xs u-sheet-1">
        <div className="u-palette-2-base u-shape u-shape-rectangle u-shape-1" />
        <div className="u-gallery u-layout-grid u-lightbox u-no-transition u-show-text-on-hover u-gallery-1">
          <div className="u-gallery-inner u-gallery-inner-1">
            <div className="u-effect-fade u-gallery-item">
              <div
                className="u-back-slide"
                data-image-width={626}
                data-image-height={417}
              >
                <img
                  className="u-back-image u-expanded"
                  src="img/1.jpg" alt="pic"
                />
              </div>
              <div className="u-over-slide u-shading u-over-slide-1">
                {/* <h3 className="u-gallery-heading" ></h3>
              <p className="u-gallery-text" ></p> */}
              </div>
            </div>
            <div className="u-effect-fade u-gallery-item">
              <div
                className="u-back-slide"
                data-image-width={462}
                data-image-height={417}
              >
                <img
                  className="u-back-image u-expanded"
                  src="img/2.png" alt="pic"
                />
              </div>
              <div className="u-over-slide u-shading u-over-slide-2">
                {/* <h3 className="u-gallery-heading" />
              <p className="u-gallery-text" /> */}
              </div>
            </div>
            <div className="u-effect-fade u-gallery-item">
              <div
                className="u-back-slide"
                data-image-width={462}
                data-image-height={417}
              >
                <img
                  className="u-back-image u-expanded "
                  src="img/3.jpg" alt="pic"
                />
              </div>
              <div className="u-over-slide u-shading u-over-slide-3">
                {/* <h3 className="u-gallery-heading" />
              <p className="u-gallery-text" /> */}
              </div>
            </div>
            <div className="u-effect-fade u-gallery-item">
              <div
                className="u-back-slide"
                data-image-width={462}
                data-image-height={417}
              >
                <img
                  className="u-back-image u-expanded"
                  src="img/4.jpg" alt="pic"
                />
              </div>
              <div className="u-over-slide u-shading u-over-slide-4">
              </div>
            </div>
            <div className="u-effect-fade u-gallery-item">
              <div
                className="u-back-slide"
                data-image-width={462}
                data-image-height={417}
              >
                <img
                  className="u-back-image u-expanded"
                  src="img/5.jpg" alt="pic"
                />
              </div>
              <div className="u-over-slide u-shading u-over-slide-5">
       
              </div>
            </div>
            {/* <div className="u-effect-fade u-gallery-item">
              <div
                className="u-back-slide"
                data-image-width={462}
                data-image-height={417}
              >
                <img
                  className="u-back-image u-expanded"
                  src="//images01.nicepage.com/c461c07a441a5d220e8feb1a/6d6a4151018c50378a2c01f5/vcvc.jpg" alt="pic"
                />
              </div>
              <div className="u-over-slide u-shading u-over-slide-6">
                
              </div>
            </div> */}
          </div>
        </div>
        <div className="u-container-style u-expanded-width-xs u-group u-white u-group-1 shadow-lg  ">
          <div className="u-container-layout u-valign-middle u-container-layout-1 ">
            <h2 className="u-text u-text-1">Success STORY</h2>
            <p className="u-text u-text-2">
              A great entrepreneur who has fueled his dreams with passion, determination, and hard work.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
